.home__introduction {
  text-align: justify;
}

.home__cards {
  height: 100%
}

.home__profile_image {
  max-width: 500px;
  width: 60%;
  height: auto;
}

.home__cards_links {
  margin-top: auto;
}

.home__cards_body_links {
  display: flex;
  flex-direction: column
}