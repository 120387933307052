.nav {
    font-size: 10pt;
  }
  .navBrand {
    font-size: 12pt;
  }
  
  .nav a:active,
  .nav a.active {
    color:  rgb(100,100,100);
  }